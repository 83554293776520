export const columnsCategoriaMensajes = [
    {
        accessor: 'acciones',
        Header: 'Acciones'
    },
    {
        accessor: 'id',
        Header: 'Id'
    },
    {
        accessor: 'descripcion',
        Header: 'Descripción'
    },
];