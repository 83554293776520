export const columnsPerfiles = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: 'description',
      Header: 'Descripción'
    },
    {
      accessor: 'isPromather',
      Header: 'Tipo'
    },
    {
      accessor: 'typePerfil',
      Header: 'Tipo de Perfil'
    },
    {
      accessor: 'dimensional',
      Header: 'Dimensional'
    },
    {
      accessor: 'operational',
      Header: 'Operacional'
    },
    {
      accessor: 'report',
      Header: 'Reportes'
    },
    {
      accessor: 'message',
      Header: 'Mensajes'
    },
    {
      accessor: 'tecnica',
      Header: 'Técnica'
    },
    {
      accessor: 'economic',
      Header: 'Económica'
    }
]

export const columnsUsuarios = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'telefono',
    Header: 'Telefono',
  },
  {
    accessor: 'email',
    Header: 'Email',
  },
  {
    accessor: 'rut',
    Header: 'Rut',
  },
]

export const perfilesDisabledTable = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'name',
    Header: 'Nombre'
  },
  {
    accessor: 'description',
    Header: 'Descripción'
  },
]

export const perfilData = [
  {
    nombre: 'Gerente',
    tipo: 'Promather',
    dimensional: 'Si',
    operacional: 'Si',
    reportes: 'Si',
    mensajes: 'Si',
    tecncia: 'Si',
    economica: 'Sid'
  },
  
]

export const columnsBitacoraEntity = [
  {
    accessor: 'nombreUser',
    Header: 'Usuario'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'fecha_transaccion',
    Header: 'Fecha'
  },
  {
    accessor: 'hora_transaccion',
    Header: 'Hora'
  },
  {
    accessor: 'nombreEntidad',
    Header: 'Entidad'
  },
]