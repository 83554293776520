
export const columns = [
  {
    accessor: 'accion',
    Header: 'Acciones'
  },
  {
    accessor: 'nombreEstado',
    Header: 'Estado'
  },
  {
    accessor: 'nombre',
    Header: 'Propuesta'
  },
];


export const columnsCostos = [
  {
    accessor: 'accion',
    Header: 'Acciones'
  },
  {
    accessor: 'nameTipoCosto',
    Header: 'Tipo de Costo'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción'
  },
  {
    accessor: 'valor',
    Header: 'Valor'
  },
  {
    accessor: 'diaCosto',
    Header: 'Dia del Costo'
  },
  {
    accessor: 'periodo',
    Header: 'Periodo'
  }
];

export const columnsCostosSinAccion = [
  {
    accessor: 'nameTipoCosto',
    Header: 'Tipo de Costo'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción'
  },
  {
    accessor: 'valor',
    Header: 'Valor'
  },
  {
    accessor: 'diaCosto',
    Header: 'Dia del Costo'
  },
  {
    accessor: 'periodo',
    Header: 'Periodo'
  },
];

export const data = [
  {
    item: 'Costo sello',
    costo: '2000',
    total: '4000'
  },        
  {
      item: 'Mano de obra',
      costo: '5000',
      total: '10000'
  }
];