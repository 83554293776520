
export const columns = [
  {
    accessor: 'accion',
    Header: 'Acciones'
  },
  {
    accessor: 'title',
    Header: 'Título'
  },
  {
    accessor: 'author',
    Header: 'Autor'
  },
  {
    accessor: 'dateVisit',
    Header: 'Fecha'
  },
  {
    accessor: 'published',
    Header: 'N° Semana'
  }
];