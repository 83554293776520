
export const columnsIndustria = [
    {
      accessor: 'acciones',
      Header: 'Acciones'
    },
    {
      accessor: 'id',
      Header: 'Id'
    },
    {
      accessor: 'nombre',
      Header: 'Nombre'
    },
];

export const columnsMarca = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsModelo = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'marca',
    Header: 'Marca'
  },
];

export const columnsClasificacion = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsCargo = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsTipo = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsEstadoPropuesta = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsTipoCosto = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsTipoUnidadMedida = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsUnidadMedida = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'tipoUnidadMedida',
    Header: 'Tipo Unidad Medida'
  },
];

export const columnsBitacora = [
  {
    accessor: 'id_usuario',
    Header: 'Id Usuario'
  },
  {
    accessor: 'nombre_usuario',
    Header: 'Nombre Usuario'
  },
  {
    accessor: 'apellido_usuario',
    Header: 'Apellido Usuario'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'fecha',
    Header: 'Fecha'
  },
  {
    accessor: 'hora',
    Header: 'Hora'
  },
  {
    accessor: 'nombre_evento',
    Header: 'Evento'
  },
  {
    accessor: 'id_data',
    Header: 'Id Data'
  }
]

export const columnsBitacoraEntity = [
  {
    accessor: 'nombreUser',
    Header: 'Usuario'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'fecha_transaccion',
    Header: 'Fecha'
  },
  {
    accessor: 'hora_transaccion',
    Header: 'Hora'
  },
  {
    accessor: 'nombreEntidad',
    Header: 'Entidad'
  },
]

export const columnsTiposMensajes = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'description',
    Header: 'Descripción'
  },
]

export const columnsPaises = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'nombre',
    Header: 'Pais'
  },
]

export const columnsSizeEquipos = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'descripcion',
    Header: 'Tamaño'
  },
]

export const columnsRegiones = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'nombre',
    Header: 'Region'
  },
  {
    accessor: 'pais',
    Header: 'Pais'
  },
]

export const columnsCiudades = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'descripcion',
    Header: 'Ciudad'
  },
  {
    accessor: 'distrito',
    Header: 'Distrito'
  },
  {
    accessor: 'region',
    Header: 'Región'
  },
  {
    accessor: 'pais',
    Header: 'Pais'
  },
]

export const dataPaise = [
]