export const columnsListaAtributos = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
];

export const columnsAtributos = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'description',
    Header: 'Descripción'
  },
  {
    accessor: 'isDimensional',
    Header: '¿Es Dimensional?'
  },
  {
    accessor: 'tipoMedida',
    Header: 'Tipo de Medida'
  },
]

export const columnsEquipos = [
    {
      accessor: 'acciones',
      Header: 'Acciones'
    },
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: 'description',
      Header: 'Descripción'
    },
];

export const columnsBitacoraEntity = [
  {
    accessor: 'nombreUser',
    Header: 'Usuario'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'fecha_transaccion',
    Header: 'Fecha'
  },
  {
    accessor: 'hora_transaccion',
    Header: 'Hora'
  },
  {
    accessor: 'nombreEntidad',
    Header: 'Entidad'
  },
]

export const columnsEquipo = [
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'name',
    Header: 'Nombre'
  },
  {
    accessor: 'brand',
    Header: 'Marca'
  },
  {
    accessor: 'model',
    Header: 'Modelo'
  },
  {
    accessor: 'area',
    Header: 'Área'
  },
  {
    accessor: 'description',
    Header: 'Descripción'
  },
];

export const columnsBitacora = [
  {
    accessor: 'id_usuario',
    Header: 'Id Usuario'
  },
  {
    accessor: 'nombre_usuario',
    Header: 'Nombre Usuario'
  },
  {
    accessor: 'apellido_usuario',
    Header: 'Apellido Usuario'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'fecha',
    Header: 'Fecha'
  },
  {
    accessor: 'hora',
    Header: 'Hora'
  },
  {
    accessor: 'nombre_evento',
    Header: 'Evento'
  },
  {
    accessor: 'id_data',
    Header: 'Id Data'
  }
]